import { ApolloProvider } from '@apollo/client'
import { ErrorBoundary } from '@sentry/nextjs'
import { AppProps } from 'next/app'
import Head from 'next/head'
import { usePathname, useSearchParams } from 'next/navigation'
import React, { useEffect, useRef } from 'react'

import Analytics from '../utils/Analytics'
import { useApollo } from '../utils/ApolloClient'

const RydeIncWeb: React.FC<AppProps> = ({ Component, pageProps }) => {
  const pathname = usePathname()
  const searchParams = useSearchParams()
  // ref. https://github.com/vercel/next.js/discussions/42016#discussioncomment-5452267
  const savedPathNameRef = useRef(pathname)

  useEffect(() => {
    Analytics.init()
  }, [])

  useEffect(() => {
    if (pathname) Analytics.logPageView(pathname)

    // If REF has been changed, do the stuff
    if (savedPathNameRef.current !== pathname) {
      const url = pathname + searchParams.toString()
      Analytics.logPageView(url)
    }
  }, [pathname, searchParams, savedPathNameRef])

  const apolloClient = useApollo(pageProps.initialApolloState)

  return (
    <ErrorBoundary>
      <Head>
        <link rel="icon" href="/favicon.ico" />
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
      </Head>
      <ApolloProvider client={apolloClient}>
        <Component {...pageProps} />
      </ApolloProvider>
    </ErrorBoundary>
  )
}

export default RydeIncWeb
